import { radioAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

// Create helpers for defining styles
const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(radioAnatomy.keys)

// Export the base styles in the component theme
export const Radio = defineMultiStyleConfig({
    baseStyle: {
        control: {
            _checked: {
                borderColor: 'neon.100',
                bgColor: 'neon.100',
            },
        },
    },
})
