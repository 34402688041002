import { ComponentStyleConfig } from '@chakra-ui/react'

export const Heading: ComponentStyleConfig = {
    baseStyle: {
        color: `green.default`,
        fontFamily: 'Graphik',
    },
    variants: {
        'display-xl': {
            fontFamily: 'Deacon',
            fontWeight: 900,
            fontSize: '104px',
            lineHeight: '96px',
            letterSpacing: '-1.5%',
        },
        'display-l': {
            fontFamily: 'Deacon',
            fontWeight: 900,
            fontSize: '68px',
            lineHeight: '76px',
            letterSpacing: '-1.5%',
        },
        'heading-xl': {
            fontWeight: 400,
            fontSize: '48px',
            lineHeight: '54px',
        },
        'heading-l': {
            fontWeight: 400,
            fontSize: '36px',
            lineHeight: '44px',
        },
        'heading-m': {
            fontWeight: 400,
            fontSize: '28px',
            lineHeight: '36px',
        },
        'heading-s': {
            fontWeight: 400,
            fontSize: '24px',
            lineHeight: '32px',
        },
        'heading-xs': {
            fontWeight: 400,
            fontSize: '20px',
            lineHeight: '30px',
        },
    },
    defaultProps: {
        variant: 'heading-m',
    },
}
