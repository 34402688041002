import { extendTheme } from '@chakra-ui/react'
import { breakpoints } from 'theme/breakpoints'
import { colors } from 'theme/colors'
import { Alert } from 'theme/components/Alert'
import { Banner } from 'theme/components/Banner'
//components
import { Button } from 'theme/components/Button'
import { CardWrapper } from 'theme/components/CardWrapper'
import { Checkbox } from 'theme/components/Checkbox'
import { Divider } from 'theme/components/Divider'
import { Drawer } from 'theme/components/Drawer'
import { FormLabel } from 'theme/components/FormLabel'
import { Heading } from 'theme/components/Heading'
import { Input } from 'theme/components/Input'
import { Link } from 'theme/components/Link'
import { Menu } from 'theme/components/Menu'
import { Modal } from 'theme/components/Modal'
import { Progress } from 'theme/components/Progress'
import { Select } from 'theme/components/Select'
import { Skeleton } from 'theme/components/Skeleton'
import { Table } from 'theme/components/Table'
import { Tabs } from 'theme/components/Tabs'
import { Text } from 'theme/components/Text'
import { Textarea } from 'theme/components/Textarea'
import { Tooltip } from 'theme/components/Tooltip'
import { Radio } from 'theme/components/Radio'
//fonts
import { fonts } from 'theme/fonts'

export const customTheme = extendTheme({
    colors,
    styles: {
        global: (props: any) => ({
            body: {
                color: colors.green.default,
                bgColor: 'beige.background',
            },
            // Usually, input styles should handle this, but placeholder for react select is basically just treated as plain content wrapped in a div, alternatives are welcome
            '#react-select-2-placeholder': {
                color: `${colors.captions.placeholder} !important`,
            },

            // Chakra popovers have a min-width of max content which breaks the layout on smaller screens, this hack fixes it
            '.chakra-popover__popper': {
                minWidth: '0 !important',
            },
            // react big calendar popover
            '.rbc-overlay': {
                zIndex: 'popover',
                '.rbc-overlay-header': {
                    fontSize: '16px',
                    fontWeight: 500,
                    mx: '5px',
                    border: 'none',
                },
                py: '15px',
                px: '5px',
                rounded: '10px',
                minWidth: '190px !important',
            },
        }),
    },
    fonts,
    components: {
        Button,
        Link,
        CardWrapper,
        Text,
        Heading,
        Input,
        Textarea,
        FormLabel,
        Drawer,
        Alert,
        Progress,
        Tooltip,
        Checkbox,
        Skeleton,
        Tabs,
        Table,
        Menu,
        Modal,
        Divider,
        // Note: Using key 'Select' directly prevents variants from working properly
        CustomSelect: Select,
        Banner,
        Radio,
    },
    breakpoints,
})
