import { ComponentStyleConfig, defineStyle } from '@chakra-ui/react'
import { extractInputPlaceholderStyle } from 'lib/chakra'

const baseStyle = defineStyle(({ theme }) => {
    const placeholderColor = extractInputPlaceholderStyle(theme as any).color

    return {
        menu: {
            height: '44px',
        },
        valueContainer: {
            px: '20px',
            py: '11px',
            fontSize: '14px',
            lineHeight: '22px',
        },
        placeholder: {
            color: placeholderColor,
            fontSize: '14px',
            lineHeight: '22px',
        },
        menuList: {
            py: '8px',
        },
        option: {
            p: '8px 24px !important',
            fontSize: '14px',
            lineHeight: '22px',
        },
    }
})

const variants = {
    'cycle-number': defineStyle(() => ({
        menu: {
            w: '200px',
        },
        dropdownIndicator: {
            pl: '0px',
            pr: '12px',
            bgColor: 'transparent',
        },
        valueContainer: {
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '22px',
            pl: '12px',
            pr: '6px',
        },
        placeholder: {
            fontSize: '14px',
            lineHeight: '22px',
        },
        control: {
            flexWrap: 'unset',
            bgColor: 'transparent',
            borderColor: 'stroke.light',
            borderWidth: '1px',
            borderStyle: 'solid',
            borderRadius: '6px',
            _focusVisible: {
                bgColor: 'unset',
            },
            _hover: {
                bgColor: 'green.transparent_3',
            },
            _active: {
                outline: 'none',
                bgColor: 'bg.green10',
            },
        },
        option: {
            bgColor: 'transparent',
            h: '34px',
            _hover: {
                bgColor: 'green.transparent_3',
            },
            fontSize: '14px',
            lineHeight: '22px',
            color: 'green.default',
        },
    })),
    'date-picker': defineStyle(() => ({
        menu: {
            w: '200px',
        },
        dropdownIndicator: {
            p: 0,
            bgColor: 'transparent',
        },
        valueContainer: {
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '16px',
            p: 0,
        },
        placeholder: {
            fontSize: '14px',
            lineHeight: '20px',
        },
        control: {
            py: 0,
            px: 0,
            h: '20px',
            w: '150px',
            minHeight: '20px',
            bgColor: 'transparent',
            border: 'none',
            _focusVisible: {
                bgColor: 'unset',
            },
            _hover: {
                bgColor: 'green.transparent_3',
            },
            _active: {
                outline: 'none',
                bgColor: 'bg.green10',
            },
        },
        option: {
            bgColor: 'transparent',
            h: '34px',
            _hover: {
                bgColor: 'green.transparent_3',
            },
            fontSize: '14px',
            lineHeight: '22px',
            color: 'green.default',
        },
    })),
}

// This component also uses some styles from Input
export const Select: ComponentStyleConfig = {
    baseStyle,
    variants,
}
